import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary';

const BMN = lazy(() => import('./BMN'));

const stripePromise = loadStripe('pk_live_51PiqxrBN1EOPeuRx8VrC4tODQZhtErHioBJNE0adkloxOONxGzefbH9SKwDph6bNGRkkpBujXZN88elc8TST737E00zGq4K5HX');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Elements stripe={stripePromise}>
        <Suspense fallback={<div>Loading...</div>}>
          <BMN />
        </Suspense>
      </Elements>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();